const hotelRouter = {
  path: '/marketing',
  component: () => import(/* webpackChunkName: "marketing-view-index" */ '../views/index.vue'),
  children: [
    // 定向优惠券

    {
      path: 'hotel/couponsLists',
      name: 'couponsLists',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-coupon-manager/coupons-lists.vue'),
    },
    {
      path: 'hotel/createCoupons',
      name: 'createCoupons',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-coupon-manager/create-coupons.vue'),
    },
    {
      path: 'hotel/activityLists',
      name: 'activityLists',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-coupon-manager/activity-lists.vue'),
    },
    {
      path: 'hotel/bindCoupons',
      name: 'hotel-bindCoupons',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-coupon-manager/bind-coupons.vue'),
    },
    {
      path: 'hotel/createActivity',
      name: 'hotel-createActivity',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-coupon-manager/create-activity.vue'),
    },
    // 全员营销
    {


      path: 'everyoneSale',
      name: 'everyoneSale',
      meta: {
        permissionCode: 'cp-hotel-everyoneSale',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/everyone-sale/everyone-sale.vue'),
    },
    {
      path: 'everyoneSalePeople',
      name: 'everyoneSalePeople',
      meta: {
        permissionCode: 'cp-hotel-everyoneSale',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/everyone-sale/everyone-sale-people.vue'),
    }, {
      path: 'bountyManagementNew',
      name: 'bountyManagementNew',
      meta: {
        permissionCode: 'cp-hotel-everyoneSale',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/everyone-sale/bounty-management-new.vue'),

    },

    // 验券中心

    {
      path: 'checkCoupons',
      name: 'checkCoupons',
      meta: {
        h_authCode: 'YQZX',
        permissionCode: 'cp-hotel-checkCouponCenter',
        routerType: 3,

      },
      component: () => import('../views/hotel-management/check-coupons/check-coupons.vue'),
    }, {
      path: 'usedCoupons',
      name: 'usedCoupons',
      meta: {
        permissionCode: 'cp-hotel-checkCouponCenter',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/check-coupons/used-coupons.vue'),

    },
  ],
};
export default hotelRouter;
