<!--
 * @Descripttion:
 * @version:
 * @Author: wangxiaole
 * @Date: 2020-02-19 14:00:54
 * @LastEditors:
 * @LastEditTime: 2022-07-07 16:32:49
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
<style >
.el-date-picker__editor-wrap .el-input__inner{
  width: 116px !important;
}
.el-date-range-picker__editor .el-input__inner{
  width: 116px !important;
}
.flex{
  display: flex;
}
.flex-center{
  display: flex;
  align-items: center;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
