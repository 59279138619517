<template>
  <el-form label-position="left" label-width="95px">
    <el-form-item>
      <div class="back-bg" @click="gobackClick">
        <i class="iconfont Business-PC-Forward back-icon"></i>
        <span class="back-text">{{ title }}</span>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>

export default {
  props: {
    title:{
      type: String,
      default: '返回'
    }
  },

  data() {
    return {

    }
  },
  methods: {
    gobackClick() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped>
.back-bg {
  display: flex;
  align-items: center;
  margin-left: -90px;
  cursor: pointer;
}
.back-bg .back-icon{
  color: #FFC600;
}
.back-bg .back-text {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #FFC600;
  line-height: 24px;
  /* font-weight: 700; */
  margin-left: 10px;
}
</style>
