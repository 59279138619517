<template>
  <div class="conten-title">
    <p class="conten-title-prefix"></p>
    <p class="conten-title-text">{{title}}</p>
  </div>
</template>
<script>

export default {
  props: {
    title: {
      type:String,
      default:''
    }
  },

  data() {
    return {

    }
  },
  methods: {
  }
}
</script>

<style scoped>
.conten-title-prefix{
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #FFC600;
  border-radius: 0px 100px 0px 0px;
}
.conten-title-text{
  display: inline-block;
  margin-left: 8px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  font-weight: 700;
}
</style>
