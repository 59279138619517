const groupRouter = {
  path: '/marketing',
  component: () => import(/* webpackChunkName: "marketing-view-index" */ '../views/index.vue'),
  children: [
    // 营销短信
    {
      path: 'smsSend',
      name: 'smsSend',
      meta: {
        permissionCode: 'cp-smsMarketing',
        routerType: 2,
      },
      component: () => import('../views/group-management/sms-marketing/sms-send.vue'),
    },
    {
      path: 'smsSetting',
      name: 'smsSetting',
      meta: {
        permissionCode: 'cp-smsMarketing',
        routerType: 2,
      },
      component: () => import('../views/group-management/sms-marketing/sms-setting.vue'),
    },
    {
      path: 'smsTestSetting',
      name: 'smsTestSetting',
      meta: {
        permissionCode: 'cp-smsMarketing',
        routerType: 2,
      },
      component: () => import('../views/group-management/sms-marketing/sms-test-setting.vue'),
    },
    {
      path: 'addSMSTemplate',
      name: 'addSMSTemplate',
      meta: {
        permissionCode: 'cp-smsMarketing',
        routerType: 2,
      },
      component: () => import('../views/group-management/sms-marketing/add-template.vue'),
    },
    {
      path: 'smsSendHistory',
      name: 'smsSendHistory',
      meta: {
        permissionCode: 'cp-smsMarketing',
        routerType: 2,
      },
      component: () => import('../views/group-management/sms-marketing/sms-send-history.vue'),

    },
    // 报价排查工具

    {
      path: 'priceViewUtil',
      name: 'priceViewUtil',
      meta: {
        permissionCode: 'cp-group-priceViewUtils',
        routerType: 2,
      },
      component: () => import('../views/group-management/price-view-manage/price-view-manage.vue'),

    },
    // 小程序码管理

    {
      path: 'miniQrCodeList',
      name: 'group-miniQrCodeList',
      meta: {
        permissionCode: 'cp-group-miniCodeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/mini-qr-code-manager/mini-qr-code-list.vue'),
    },
    {
      path: 'createMiniCode',
      name: 'createMiniCode',
      meta: {
        permissionCode: 'cp-group-miniCodeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/mini-qr-code-manager/create-mini-code.vue'),

    },
    {
      path: 'createMiniCodeBatch',
      name: 'createMiniCodeBatch',
      meta: {
        permissionCode: 'cp-group-miniCodeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/mini-qr-code-manager/batchCreat'),

    },
    // wifi二维码

    {
      path: 'wifiqrmanager',
      name: 'wifiqrmanager',
      meta: {
        permissionCode: 'cp-group-wifiQrcode',
        routerType: 2,
      },
      component: () => import('../views/group-management/wifi-qr-manager/shop-service-qr-code.vue'),

    },
    // 小程序广告管理
    {
      path: 'adManage',
      name: 'adManage',
      meta: {
        permissionCode: 'cp-adManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/ad-manage/ad-manage-list.vue'),
    },
    {
      path: 'createAd',
      name: 'createAd',
      meta: {
        permissionCode: 'cp-adManage',
        routerType: 2
      },
      component: () => import('../views/group-management/ad-manage/ad-create.vue'),
    },
    {
      path: 'xdAdManage',
      name: 'xdAdManage',
      meta: {
        permissionCode: 'cp-adManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/ad-manage/ad-manage-xd.vue'),
    },
    {
      path: 'createXdAd',
      name: 'createXdAd',
      meta: {
        permissionCode: 'cp-adManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/ad-manage/ad-create-xd.vue'),
    },

    //通知管理
    {
      path: 'noticeManage',
      name: 'noticeManage',
      meta: {
        permissionCode: 'cp-group-noticeManage',
        routerType: 2,
        keepAlive: true
      },
      component: () => import('../views/group-management/notice-manage/notice-list.vue'),
    },
    {
      path: 'createNotice',
      name: 'createNotice',
      meta: {
        permissionCode: 'cp-group-noticeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/notice-manage/create-notice.vue'),
    },
    // 二维码管理

    {
      path: 'createQR',
      name: 'createQR',
      meta: {
        permissionCode: 'cp-group-qrCodeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/qr-manager/create-qr.vue'),
    },
    {
      path: 'qrList',
      name: 'qrList',
      meta: {
        permissionCode: 'cp-group-qrCodeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/qr-manager/qr-list.vue'),
    },
    {
      path: 'addFristActivity',
      name: 'addFirstActivity',
      meta: {
        permissionCode: 'cp-group-qrCodeConfig',
        routerType: 2,
      },
      component: () => import('../views/group-management/qr-manager/add-frist-activity.vue'),
    }, {
      path: 'addSecondActivity',
      name: 'addSecondActivity',
      meta: {
        permissionCode: 'cp-group-qrCodeConfig',
        routerType: 2,
      },
      component: () => import('../views/group-management/qr-manager/add-second-activity.vue'),
    },
    {
      path: 'makeQRcode',
      name: 'makeQRCode',
      meta: {
        permissionCode: 'cp-group-qrCodeManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/qr-manager/make-qr-code.vue'),
    },


    // 卖卡

    {
      path: 'memberLevelUp',
      name: 'memberLevelUp',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/member-level-up/member-level-up.vue'),
    },
    // 联合品牌logo配置-列表
    {
      path: 'memberUpdate',
      name: 'memberUpdate',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/member-update/member-update.vue'),
    },
    // 联合品牌logo配置-创建
    {
      path: 'memberCreate',
      name: 'memberCreate',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/member-update/member-create.vue'),
    },
    {
      path: 'uploadThirdCard',
      name: 'uploadThirdCard',
      meta: {
        permissionCode: 'cp-group-memberCardSale',
        routerType: 2,
      },
      component: () => import('../views/group-management/member-update/upload-third-card.vue'),
    },

    // 定向优惠券

    {
      path: 'couponsLists',
      name: 'couponList',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/coupons-lists.vue'),
    },
    {
      path: 'sendCouponToPerson',
      name: 'sendCouponToPerson',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/send-coupon-to-person.vue'),
    },
    {
      path: 'createCoupons',
      name: 'group-createCoupons',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/create-coupons.vue'),
    },
    {
      path: 'createActivity',
      name: 'group-createActivity',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/create-activity.vue'),
    },
    {
      path: 'couponsExport',
      name: 'group-couponsExport',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/coupons-export.vue'),
    },
    {
      path: 'sendDetail',
      name: 'sendDetail',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/send-detail.vue'),
    },

    {
      path: 'bindCoupons',
      name: 'group-bindCoupons',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/directional-coupons/bind-coupons.vue'),
    },
    {
      path: 'activityLists',
      name: 'group-activityLists',
      meta: {
        permissionCode: 'cp-group-directionalCoupon',
        routerType: 2,
        keepAlive: true
      },
      component: () => import('../views/group-management/directional-coupons/activity-lists.vue'),

    },
    // 渠道分类

    {
      path: 'channelSourceMarkType',
      name: 'channelSourceMarkType',
      meta: {
        permissionCode: 'cp-group-channelSourceMarkType',
        routerType: 2,
      },
      component: () => import('../views/group-management/channel-source-mark-type/channel-source-mark.vue'),
    },
    // 在线帮助中心

    {
      path: 'questionList',
      name: 'questionList',
      meta: {
        permissionCode: 'cp-group-helpCenter',
        routerType: 2,
      },
      component: () => import('../views/help-center/question-list.vue'),
    },
    {
      path: 'editQuestion',
      name: 'editQuestion',
      meta: {
        permissionCode: 'cp-group-helpCenter',
        routerType: 2,
      },
      component: () => import('../views/help-center/edit-question.vue'),

    },
    // 活动页管理

    {
      path: 'activityPageManage',
      name: 'activityPageManage',
      meta: {
        permissionCode: 'cp-group-apManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-page-manage/ap-manage-list.vue'),
    },
    {
      path: 'createApHotel',
      name: 'createApHotel',
      meta: {
        permissionCode: 'cp-group-apManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-page-manage/ap-create-hotel.vue'),
    },
    {
      path: 'createApGoods',
      name: 'createApGoods',
      meta: {
        permissionCode: 'cp-group-apManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-page-manage/ap-create-goods.vue'),
    },
    {
      path: 'createApPic',
      name: 'createApPic',
      meta: {
        permissionCode: 'cp-group-apManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-page-manage/ap-create-pics.vue'),
    },
    {
      path: 'createApPersonal',
      name: 'createApPersonal',
      meta: {
        permissionCode: 'cp-group-apManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-page-manage/ap-create-personal.vue'),

    },
    {
      path: 'addMediaMaterial',
      name: 'addMediaMaterial',
      meta: {

        routerType: 2,
      },
      component: () => import('../views/group-management/qr-manager/add-media-material.vue'),
    },

    // 小程序码场景管理
    {
      path: 'miniSceneConfig',
      name: 'miniSceneConfig',
      meta: {
        permissionCode: 'cp-group-qrCodeConfig',
        routerType: 2,
      },
      component: () => import('../views/group-management/minisceneconfig/mini-scene-config.vue'),
    },
    {
      path: 'addMiniSceneConfig',
      name: 'addMiniSceneConfig',
      meta: {
        permissionCode: 'cp-group-qrCodeConfig',
        routerType: 2,
      },
      component: () => import('../views/group-management/minisceneconfig/add-mini-scene-config.vue'),
    },
    // 打点字典可视化工具
    {
      path: 'logEnumManagement',
      name: 'logEnumManagement',
      meta: {
        permissionCode: 'cp-group-logEnumManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/log-enum-management/log-enum-management.vue'),
    },
    // 免房券 券列表
    {
      path: 'freeRoomCouponList',
      name: 'freeRoomCouponList',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/free-room-couponlist.vue'),
    },
    // 免房券 券批次
    {
      path: 'freeRoomCouponBatch',
      name: 'freeRoomCouponBatch',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
        keepAlive: true
      },
      component: () => import('../views/group-management/free-room-coupon/free-room-couponbatch.vue'),
    },
    // 免房券 活动
    {
      path: 'freeRoomActivity',
      name: 'freeRoomActivity',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/free-room-activity.vue'),
    },
    // 免房券 配置库存
    {
      path: 'freeRoomCouponInventory',
      name: 'freeRoomCouponInventory',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/free-room-inventory.vue'),
    },
    // 免房券 新增或编辑库存
    {
      path: 'editFreeRoomCouponInventory',
      name: 'editFreeRoomCouponInventory',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/edit-free-room-inventory.vue'),
    },
    // 免房券 新增或编辑批次号
    {
      path: 'editFreeRoomCouponBatch',
      name: 'editFreeRoomCouponBatch',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/edit-free-room-couponbatch.vue'),
    },
    // 免房券 券详情
    {
      path: 'FreeRoomCouponDetail',
      name: 'FreeRoomCouponDetail',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/free-room-coupondetail.vue'),
    },
    // 免房券 券批次详情
    {
      path: 'FreeRoomCouponBatchDetail',
      name: 'FreeRoomCouponBatchDetail',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/free-room-couponbatchdetail.vue'),
    },
    // 免房券 延续库存
    {
      path: 'extendFreeRoomInventory',
      name: 'extendFreeRoomInventory',
      meta: {
        permissionCode: 'cp-group-freeRoomCoupon',
        routerType: 2,
      },
      component: () => import('../views/group-management/free-room-coupon/extend-free-room-inventory.vue'),
    },
    // 乾坤袋首页
    {
      path: 'marketingIndex',
      name: 'marketingIndex',
      meta: {
        permissionCode: 'cp-group-lcMarketing',
        routerType: 2,
      },
      component: () => import('../views/group-management/lc-marketing/index/index.vue'),
    },
    // 首页内容管理
    {
      path: 'contentManage',
      name: 'contentManage',
      meta: {
        permissionCode: 'cp-group-findX',
        routerType: 2,
      },
      component: () => import('../views/group-management/content-manage/index.vue'),
    },
     // 本地生活集城市配置
     {
      path: 'lifeCitySet',
      name: 'lifeCitySet',
      meta: {
        permissionCode: 'cp-group-findX',
        routerType: 2,
      },
      component: () => import('../views/group-management/life-city-set/index.vue'),
    },
    // 发现X配置
    {
      path: 'findXIndex',
      name: 'findXIndex',
      meta: {
        permissionCode: 'cp-group-findX',
        routerType: 2,
      },
      component: () => import('../views/group-management/findx-manage/index.vue'),
    },
    // 编辑城市攻略
    {
      path: 'editCityStrategy',
      name: 'editCityStrategy',
      meta: {
        permissionCode: 'cp-group-findX',
        routerType: 2,
      },
      component: () => import('../views/group-management/findx-manage/child/editCityStrategy.vue'),
    },
    // 抽奖活动列表
    {
      path: 'marketingLotteryList',
      name: 'marketingLotteryList',
      meta: {
        permissionCode: 'cp-group-lotteryActivity',
        routerType: 2,
      },
      component: () => import('../views/group-management/lottery-activity/lottery/lottery-list.vue'),
    },
    // 创建抽奖活动
    {
      path: 'marketingLotteryCreate',
      name: 'marketingLotteryCreate',
      meta: {
        permissionCode: 'cp-group-lotteryActivity',
        routerType: 2,
      },
      component: () => import('../views/group-management/lottery-activity/lottery/lottery-create.vue'),
    },
    // 抽奖活动关联奖品
    {
      path: 'marketingLotteryAddPrize',
      name: 'marketingLotteryAddPrize',
      meta: {
        permissionCode: 'cp-group-lotteryActivity',
        routerType: 2,
      },
      component: () => import('../views/group-management/lottery-activity/lottery/lottery-add-prize.vue'),
    },
    // 抽奖活动中奖名单
    {
      path: 'marketingLotteryNameList',
      name: 'marketingLotteryNameList',
      meta: {
        permissionCode: 'cp-group-lotteryActivity',
        routerType: 2,
      },
      component: () => import('../views/group-management/lottery-activity/lottery/lottery-name-list.vue'),
    },
    // 促销活动列表页
    {
      path: 'promotionManagement',
      name: 'promotionManagement',
      meta: {
        permissionCode: 'cp-promotionManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/promotion-manage/promotion-list.vue'),
    },
    // 创建促销活动页
    {
      path: 'createPromotion',
      name: 'createPromotion',
      meta: {
        permissionCode: 'cp-promotionManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/promotion-manage/create-promotion.vue'),
    },
    // 活动报名工具列表页
    {
      path: 'activityRegistrationTool',
      name: 'activityRegistrationTool',
      meta: {
        permissionCode: 'cp-group-activityRegistrationTool',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-registration-tool/registration-list.vue'),
    },
    // 创建活动报名工具
    {
      path: 'createRegistration',
      name: 'createRegistration',
      meta: {
        permissionCode: 'cp-group-activityRegistrationTool',
        routerType: 2,
      },
      component: () => import('../views/group-management/activity-registration-tool/create-registration.vue'),
    },
    // 品牌动态创建促销活动页
    {
      path: 'createMpPromotion',
      name: 'createMpPromotion',
      meta: {
        permissionCode: 'cp-promotionManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/promotion-manage/create-promotion.vue'),
    },

    // 中奖名单详情
    {
      path: 'marketingLotteryNameDetail',
      name: 'marketingLotteryNameDetail',
      meta: {
        permissionCode: 'cp-group-lotteryActivity',
        routerType: 2,
      },
      component: () => import('../views/group-management/lottery-activity/lottery/lottery-name-detail.vue'),
    },
    // 短链生成工具
    {
      path: 'shortChainTools',
      name: 'shortChainTools',
      meta: {
        permissionCode: 'cp-group-shortChainTools',
        routerType: 2,
      },
      component: () => import('../views/group-management/short-chain-tools/index.vue'),
    },
    // 兑换码生成工具
    {
      path: 'exchangeCodeTools',
      name: 'exchangeCodeTools',
      meta: {
        permissionCode: 'cp-group-exchangeCodeTools',
        routerType: 2,
        keepAlive:true
      },
      component: () => import('../views/group-management/exchange-code-tool/index.vue')
    },
    {
      path: 'searchExchagneCode',
      name: 'searchExchagneCode',
      meta: {
        permissionCode: 'cp-group-exchangeCodeTools',
        routerType: 2,
      },
      component: () => import('../views/group-management/exchange-code-tool/search-index.vue')
    },
    {
      path: 'createExchangeCode',
      name: 'createExchangeCode',
      meta: {
        permissionCode: 'cp-group-exchangeCodeTools',
        routerType: 2,
      },
      component: () => import('../views/group-management/exchange-code-tool/create-code.vue')
    },
    {
      path: 'exchangeDetail',
      name: 'exchangeDetail',
      meta: {
        permissionCode: 'cp-group-exchangeCodeTools',
        routerType: 2,
      },
      component: () => import('../views/group-management/exchange-code-tool/exchange-detail.vue')
    },
    // 营销外呼
    {
      path: 'outsideCallList',
      name: 'outsideCallList',
      meta: {
        permissionCode: 'cp-group-marketingOutsideCall',
        routerType: 2,
        keepAlive:true,
      },
      component: () => import('../views/group-management/outside-call/outside-call-list.vue')
    },
    // 渠道链接管理
    {
      path: 'marketingUrlManage',
      name: 'marketingUrlManage',
      meta: {
        permissionCode: 'cp-group-marketingUrlManage',
        routerType: 2,
        keepAlive: true,
      },
      component: () => import('../views/group-management/marketing-url-manage/index.vue')
    },
    // 创建渠道链接
    {
      path: 'createMarketingUrl',
      name: 'createMarketingUrl',
      meta: {
        permissionCode: 'cp-group-marketingUrlManage',
        routerType: 2,
        keepAlive: true,
      },
      component: () => import('../views/group-management/marketing-url-manage/create.vue')
    },
    {
      path: 'createOutsideCall',
      name: 'createOutsideCall',
      meta: {
        permissionCode: 'cp-group-marketingOutsideCall',
        routerType: 2,
      },
      component: () => import('../views/group-management/outside-call/create-call.vue')
    },
    // 员工营销海报
    {
      path: 'staffPosterList',
      name: 'staffPosterList',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
        keepAlive:true
      },
      component: () => import('../views/group-management/staff-marketing-poster/poster-list.vue')
    },
    // 创建营销海报
    {
      path: 'createStaffMarketingPoster',
      name: 'createStaffMarketingPoster',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
      },
      component: () => import('../views/group-management/staff-marketing-poster/create-poster.vue')
    },
    // 异业合作
    {
      path: 'outerShortChainTools',
      name: 'outerShortChainTools',
      meta: {
        permissionCode: 'cp-group-shortChainTools',
        routerType: 2,
      },
      component: () => import('../views/group-management/outer-short-chain-tools/index.vue'),
    },
    // 批量领取会籍
    {
      path: 'batchDrawMember',
      name: 'batchDrawMember',
      meta: {
        permissionCode: 'cp-group-shortChainTools',
        routerType: 2,
      },
      component: () => import('../views/group-management/batch-draw-member/index.vue'),
    },
    // 奖励金明细
    {
      path: 'bonusInfo',
      name: 'bonusInfo',
      meta: {
        permissionCode: 'cp-group-bonusInfo',
        routerType: 2,
      },
      component: () => import('../views/group-management/bonus-info/index.vue'),
    },
    // 任务系统
    {
      path: 'marketingTaskList',
      name: 'marketingTaskList',
      meta: {
        permissionCode: 'cp-group-marketingTaskManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/task-system/index')
    },
    {
      path: 'marketingTaskEdit',
      name: 'marketingTaskEdit',
      meta: {
        permissionCode: 'cp-group-marketingTaskManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/task-system/task-edit')
    },
    {
      path: 'marketingUserList',
      name: 'marketingUserList',
      meta: {
        permissionCode: 'cp-group-marketingUserManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/user-system/index')
    },
    {
      path: 'marketingUserTaskEdit',
      name: 'marketingUserTaskEdit',
      meta: {
        permissionCode: 'cp-group-marketingUserManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/user-system/user-task-edit')
    },
    {
      path: 'marketingTaskSystemDataAnalysisList',
      name: 'marketingTaskSystemDataAnalysis',
      meta: {
        permissionCode: 'cp-group-marketingDataSystemManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/task-system-dataAnalysis/index')
    },
    {
      path: 'marketingTaskSystemDataAnalysisDetail',
      name: 'marketingTaskSystemDataAnalysisDetail',
      meta: {
        permissionCode: 'cp-group-marketingDataSystemManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/task-system-dataAnalysis/dataDetail')
    },
    {
      path: 'userTaskSystemDataAnalysisList',
      name: 'userTaskSystemDataAnalysisList',
      meta: {
        permissionCode: 'cp-group-marketingDataSystemManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/task-system-dataAnalysis/index')
    },
    {
      path: 'userTaskSystemDataAnalysisDetail',
      name: 'userTaskSystemDataAnalysisDetail',
      meta: {
        permissionCode: 'cp-group-marketingDataSystemManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/task-system-dataAnalysis/dataDetail')
    },
    // 打包产品分成配置
    {
      path: 'packageProductRewardConfig',
      name: 'packageProductRewardConfig',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/package-product-reward-config/index')
    },
    // 客房产品分成配置
    {
      path: 'roomProductRewardConfig',
      name: 'roomProductRewardConfig',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/room-product-reward-config/index')
    },
    // 营销规则
    {
      path: 'marketingRule',
      name: 'marketingRule',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/marketing-rule/index')
    },
    // 体验金开关
    {
      path: 'exMemberSwitch',
      name: 'exMemberSwitch',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
      },
      component: () => import('../views/group-management/marketing-page-manage/ex-member-switch.vue'),
    },
    // 营销知识
    {
      path: 'marketingKnowledge',
      name: 'marketingKnowledge',
      meta: {
        permissionCode: 'cp-group-marketingKnowledge',
        routerType: 2,
      },
      component: () => import('../views/group-management/marketing-knowledge-manage/index.vue'),
    },
    // 复购项目
    {
      path: 'repurchase/index',
      name: 'repurchase',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
        keepAlive: true
      },
      component: () => import('../views/group-management/repurchase/index.vue'),
    },
    // 新建复购活动
    {
      path: 'repurchase/edit',
      name: 'repurchaseEdit',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
      },
      component: () => import('../views/group-management/repurchase/edit.vue'),
    },
    {
      path: 'repurchase/reward',
      name: 'repurchaseReward',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
      },
      component: () => import('../views/group-management/repurchase/reward.vue'),
    },
    {
      path: 'repurchase/bindCoupon',
      name: 'repurchaseBindCoupon',
      meta: {
        permissionCode: 'cp-group-staffMarketingPoster',
        routerType: 2,
      },
      component: () => import('../views/group-management/repurchase/bindCoupon.vue'),
    },
    {
      path: 'rightObligor',
      name: 'rightObligorList',
      meta: {
        permissionCode: 'cp-group-rightObligor',
        routerType: 2,
        keepAlive: true
      },
      component: () => import('../views/group-management/right-obligor-manage/index.vue'),
    },
    {
      path: 'sellProductList',
      name: 'sellProductList',
      meta: {
        permissionCode: 'cp-group-rightObligor',
        routerType: 2,
      },
      component: () => import('../views/group-management/right-obligor-manage/sell-product-list'),
    }
  ],
};

export default groupRouter;
