const {
  fetch,
  download,
  urlToDownload,
  showToast,
} = window.$axios;
export default {
  showToast,
  fetch,
  download,
  urlToDownload,
};
